/* eslint-disable */

export const root_domain = "exponent.aws-amplify.dev";

const awsmobile = {
	logo_img_url : `https://${root_domain}/Logo.png`,
	aws_project_region: "us-west-2",
	aws_user_pools_web_client_id: "43sad6ggvsbn5m1o2d59eg37ic",
	aws_user_pools_id: "us-west-2_VEm4jeDPY",
	aws_backend_api_url: `https://api.${root_domain}`,
	aws_hosted_ui_url: "https://exponent-adminportal-dev001.auth.us-west-2.amazoncognito.com",
	app_callback_uri: "https://applion.netlify.app",
};
export default awsmobile;
